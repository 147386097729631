<template>
  <v-container style="padding-top: 4rem">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-autocomplete
          v-model="search"
          :items="filteredRoutes"
          label="Zoeken"
          append-icon="mdi-magnify"
          solo
          flat
          hide-details
          class="custom-search-bar"
          @input="onSearch"
          @change="navigate"
          item-text="label"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    routes: [
      { path: "/Dashboard", label: "Dashboard" },
      { path: "/Handelaars", label: "Handelaars" },
      { path: "/Klanten", label: "Klanten" },
      { path: "/Kavelsbeheren", label: "Kavelsbeheren" },
      { path: "/Rapportage", label: "Rapportage" },
      { path: "/RapportageUitdraaien", label: "Rapportage uitdraaien" },
      { path: "/Instructies", label: "Instructies" },
      // Voeg meer routes en labels toe zoals nodig
    ],
  }),
  computed: {
    filteredRoutes() {
      const query = this.search ? this.search.toLowerCase() : "";
      return this.routes.filter((route) =>
        route.label.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    onSearch() {
      // Geen specifieke logica nodig hier, de filteredRoutes computed property handelt de filtering af
    },
    navigate(item) {
      console.log("Geselecteerde item:", item);
      this.$router.push(`/${item
      }`);
    },
  },
};
</script>
<style scoped>
.custom-search-bar {
  font-size: 1.3rem;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border: 2px solid #1976d2;
}

.custom-search-bar:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-search-bar  .v-input__control {
  display: flex;
  justify-content: center;
}

.custom-search-bar .v-label {
  color: #1976d2;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
}

.custom-search-bar .v-text-field__slot {
  text-align: center;
}

.custom-search-bar input {
  font-size: 1.2rem;
  text-align: center;
}
</style>
