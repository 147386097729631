<template>
  <v-container >
    <Search />
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'Klanten' }">
          <div class="link-box">
            <img src="@/assets/dashboard/customer.png" alt="img" />
            <p class="link-box-title">Klanten</p>
            <p class="link-box-subtitle">
              Klanten toevoegen/verwijderen en bewerken
            </p>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'Handelaars' }">
          <div class="link-box">
            <img src="@/assets/dashboard/agent.png" alt="img" />
            <p class="link-box-title">Handelaars</p>
            <p class="link-box-subtitle">
              Handelaars toevoegen/verwijderen en bewerken
            </p>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'Kavelsbeheren' }">
          <div class="link-box">
            <img src="@/assets/dashboard/post-stamp.png" alt="img" />
            <p class="link-box-title">Kavels beheren</p>
            <p class="link-box-subtitle">
              kavels toevoegen/verwijderen en bewerken
            </p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'WebshopBestellingen' }">
          <div class="link-box">
            <img src="@/assets/dashboard/orders.png" alt="img" />
            <p class="link-box-title">Bestellingen</p>
            <p class="link-box-subtitle">Website Bestellingen</p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'Kassa' }">
          <div class="link-box">
            <img src="@/assets/dashboard/kassa.png" alt="img" />
            <p class="link-box-title">Kassa afrekenen</p>
            <p class="link-box-subtitle">Bestellen, betalingen</p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <a
          href="https://app.budgetinvoice.com"
          target="_blank"
          class="router-link"
        >
          <div class="link-box">
            <img src="@/assets/dashboard/invoice.png" alt="img" />
            <p class="link-box-title">Facturen</p>
            <p class="link-box-subtitle">Facturen aanmaken</p>
          </div>
        </a>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'facturenRapportage' }">
          <div class="link-box">
            <img src="@/assets/dashboard/credit-card.png" alt="img" />
            <p class="link-box-title">Betalingen</p>
            <p class="link-box-subtitle">Betaalrapporten uitdraaien</p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'BerichtenLog' }">
          <div class="link-box">
            <img src="@/assets/dashboard/email.png" alt="img" />
            <p class="link-box-title">Berichten</p>
            <p class="link-box-subtitle">Logbestanden berichten bekijken</p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mb-4">
        <router-link class="router-link" :to="{ name: 'KavelsFolder' }">
          <div class="link-box">
            <img src="@/assets/dashboard/information.png" alt="img" />
            <p class="link-box-title">Instructies</p>
            <p class="link-box-subtitle">
              Facturen, Instructie, Kavels folders
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import Search from "./Search.vue";
import firebase from "firebase";
export default {
  components:{
    Search
  },
  data() {
    return {
      showToolbar: true,
      company: "Welkon bij Postzelcentrale",
    };
  },
  methods: {
    toggleToolbar() {
      this.showToolbar = !this.showToolbar;
    },

    logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$swal.fire("Het werkt!", "Je bent uitgelogd!", "success");
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
